/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading, Paragraph } from "theme-ui"
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import PurpleBulletList from "../PurpleBulletList"

const EthosSection = () => {
  return (
    <section
      sx={{
        pt: [5, 6]
      }}
    >
      <Container variant="container1200">
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              maxWidth: "487px"
            }}
          >
            <Heading as="h3" variant="styles.h3">
              Our ethos runs deep on two core factors
            </Heading>
            <PurpleBulletList>
              <li>
                <Paragraph>
                  We understand that the long term viability of your success is
                  built upon your brand and its ability to instill loyalty.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Customers need to know that they are engaging at a level of
                  trust that runs deeper than just a great product at a great
                  price. Trust is that those characteristics that create the
                  initial value proposition will be lived out in all areas of
                  your brand and business.
                </Paragraph>
              </li>
            </PurpleBulletList>
          </Box>
          <StaticImage
            src="../../images/about-page/image-stack.png"
            alt="design image"
            sx={{
              mt: [4, 0]
            }}
          />
        </Flex>
      </Container>
    </section>
  )
}

export default EthosSection
