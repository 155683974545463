/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { ReactNode } from "react"

interface ModalProps {
  children: ReactNode | ReactNode[]
}
const Modal = ({ children }: ModalProps) => {
  return (
    <Box variant="styles.modal">
      {children}
    </Box>
  )
}

export default Modal
