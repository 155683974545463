/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import Layout from 'components/Common/Layout';
import PurpleHero from 'components/PurpleHero';
import OurMessageSection from 'components/about/OurMessageSection';
import EthosSection from 'components/about/EthosSection';
import OurTeamSection from 'components/about/OurTeamSection';
import MoveYourBrandCard from 'components/Footer/MoveYourBrandCard';

const AboutPage = ({
  data: {
    strapiAboutPage: { SEO, bios },
  },
}) => {
  return (
    <Layout
      seo={{
        title: SEO.meta_title,
        description: SEO.meta_description,
      }}
    >
      <PurpleHero heading="About" />

      <OurMessageSection />
      <EthosSection />
      <OurTeamSection bios={bios} />

      <MoveYourBrandCard
        sx={{
          m: '0 auto',
          position: 'relative',
          zIndex: 2,
          bottom: -5,
        }}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage {
    strapiAboutPage {
      SEO {
        meta_title
        meta_description
        share_image {
          localFile {
            publicURL
          }
        }
      }
      bios {
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 285, height: 285)
            }
          }
        }
        name
        details
        title
      }
    }
  }
`;
