/** @jsx jsx */
import {
  jsx,
  Card,
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Paragraph
} from "theme-ui"
import React, { useState } from "react"
import { IGatsbyImageData, GatsbyImage, getImage } from "gatsby-plugin-image"

import Modal from "components/Common/Modal"
import XButton from "components/Common/XButton"

import { useDisableBodyScroll } from "hooks/useDisableBodyScroll"

interface EmployeeCardProps {
  headshot: any
  name: string
  title: string
  details: string
}
const EmployeeCard = ({
  headshot,
  name,
  title,
  details
}: EmployeeCardProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }
  useDisableBodyScroll(modalIsOpen)

  return (
    <>
      <Card variant="employee">
        <Box className="headshotImageWrapper">
          <Box className="gradientOverlay"></Box>
          <GatsbyImage
            image={
              getImage(
                headshot.localFile.childImageSharp.gatsbyImageData
              ) as IGatsbyImageData
            }
            alt={`${name} headshot`}
          />
        </Box>
        <Heading as="h5" variant="styles.h5">
          {name}
        </Heading>
        <Text variant="text.body">{title}</Text>
        <Button
          variant="learnMore"
          className="learnMoreButton"
          onClick={toggleModal}
        >
          Learn more
        </Button>
      </Card>

      {modalIsOpen && (
        <Modal>
          <Card variant="employeeModal">
            <Flex>
              <Box
                className="headshotImageWrapper"
                sx={{
                  mr: 3
                }}
              >
                <Box className="gradientOverlay"></Box>
                <GatsbyImage
                  image={
                    getImage(
                      headshot.localFile.childImageSharp.gatsbyImageData
                    ) as IGatsbyImageData
                  }
                  alt={`${name} headshot`}
                />
              </Box>
              <Box>
                <Heading as="h5" variant="styles.h5">
                  {name}
                </Heading>
                <Text variant="text.body">{title}</Text>
              </Box>
            </Flex>
            <Paragraph
              className="details"
              sx={{
                maxWidth: "489px",
                maxHeight: "255px",
                ml: "auto",
                mt: [3, -2],
                overflowY: "auto",
                whiteSpace: "pre-wrap"
              }}
            >
              {details}
            </Paragraph>
            <XButton
              callback={toggleModal}
              sx={{
                position: "absolute",
                top: [3, 4],
                right: [3, 4]
              }}
            />
          </Card>
        </Modal>
      )}
    </>
  )
}

export default EmployeeCard
