/** @jsx jsx */
import { jsx } from "theme-ui"
import EmployeeCard from "components/about/EmployeeCard"

interface EmployeeCardsListProps {
  bios: any
  className?: string
}
const EmployeeCardsList = ({ bios, className }: EmployeeCardsListProps) => {
  return (
    <ul
      className={className}
      sx={{
        p: 0,
        m: 0,
        display: "grid",
        gridTemplateColumns: ["repeat(auto-fill, 178px)", "repeat(auto-fill, 285px)"],
        rowGap: [4, 5],
        columnGap: [3, 5],
        justifyContent: "center",
        listStyle: "none"
      }}
    >
      {bios.map((bio, index) => (
        <li key={`employee-card:${index}`}>
          <EmployeeCard {...bio} />
        </li>
      ))}
    </ul>
  )
}

export default EmployeeCardsList
