/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"

import EmployeeCardsList from "components/about/EmployeeCardsList"

interface OurTeamSectionProps {
  bios: any
}
const OurTeamSection = ({ bios }: OurTeamSectionProps) => {
  return (
    <section
      sx={{
        pt: [5, 6]
      }}
    >
      <Container variant="container1000">
        <Heading
          as="h2"
          variant="styles.h2"
          sx={{
            color: "themeBlack",
            textAlign: "center"
          }}
        >
          Our Team
        </Heading>
        <EmployeeCardsList
          bios={bios}
          sx={{
            mt: [4, 5]
          }}
        />
      </Container>
    </section>
  )
}

export default OurTeamSection
