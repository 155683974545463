/** @jsx jsx */
import { jsx, Button } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"

interface XButtonProps {
  callback: () => void
  className?: string
}
const XButton = ({ callback, className }: XButtonProps) => {
  return (
    <Button variant="icon" className={className} onClick={callback}>
      <StaticImage
        src="../../images/icons/x-icon.svg"
        alt=""
        width={14}
        height={14}
        layout="fixed"
      />
    </Button>
  )
}

export default XButton
