/** @jsx jsx */
import { jsx, Container, Heading, Box, Paragraph } from "theme-ui"

const OurMessageSection = () => {
  return (
    <section
      sx={{
        pt: [4, 6]
      }}
    >
      <Container variant="container1000">
        <Heading as="h3" variant="styles.h3">
          Mōtus exists to move brands forward.
        </Heading>
        <Heading
          sx={{
            mt: 4
          }}
          as="h4"
          variant="styles.h4"
        >
          We bring you success by growing your unique brand awareness,
          furthering your product knowledge in the consumer marketplace and
          selling products strategically online and in brick & mortar
          storefronts.
        </Heading>
        <Heading
          sx={{
            mt: 4
          }}
          as="h4"
          variant="styles.h4"
        >
          Mōtus connects your brand with your customers on the emotional,
          logical, and lifestyle level - creating brand loyalty that lasts.
        </Heading>
        <Box
          sx={{
            pr: 3,
            mt: 4
          }}
        >
          <Paragraph>
            Your company has unique needs. Our agency has a unique ability to
            listen to those needs and transcribe them into profit-soaring goals
            through proven sales, marketing, and advertising initiatives.
          </Paragraph>
          <Paragraph
            sx={{
              mt: 3
            }}
          >
            Our vast range of industry expertise spans from medical, food and
            beverage, automotive, and the great outdoors--including firearms and
            sports entertainment. Our team of top professional photographers,
            designers, copywriters, film directors, brand managers, and digital
            technology professionals can cover the entire process from start to
            finish.
          </Paragraph>
          <Paragraph
            sx={{
              mt: 3
            }}
          >
            You will thrive as we leverage your unique brand impact, pinpoint
            profit, and sustain growth.
          </Paragraph>
        </Box>
      </Container>
    </section>
  )
}

export default OurMessageSection
